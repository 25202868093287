import React from "react"
import PageLayout from "../components/page-layout"

import {
  SectionLayout,
  HeroSection,
  AboutUsShort,
  ServicesShort,
  getCurrentService,
} from "../components/sections";
import { ServicesPaths } from "../constants";


import { HERO_SECTION_ID, ABOUT_US_ID, SERVICES_ID } from "../constants"

export default function Home() {
  const securityConcierge = getCurrentService(ServicesPaths.securityConciergeGuards.slug);
  return (
    <PageLayout>
      <HeroSection sectionId={HERO_SECTION_ID} />
      <SectionLayout
        sectionId={ABOUT_US_ID}
        sectionTitle="About Us"
        bgColor="gray"
        hasFullWidth
        hasFullRow
      >
        <AboutUsShort />
      </SectionLayout>
      <SectionLayout
        bgColor="darkGrey"
        sectionId={SERVICES_ID}
        sectionTitle="Security Concierge Guard"
      >
        {securityConcierge.content}
      </SectionLayout>
      <SectionLayout
        sectionId={SERVICES_ID}
        sectionTitle="Our Services"
        hasFullWidth
        hasFullRow
      >
        <ServicesShort />
      </SectionLayout>

    </PageLayout>
  )
}
